import { useState, useEffect, useRef } from 'react'
import './memoryGame.css'
import _ from 'lodash'
import codepoints from './codepoints.json'
import Container from 'react-bootstrap/Container'



let getEmojiList = (n) => _.sampleSize(codepoints, n)

let getBoard = (alist) => {
    let boardSize = Math.floor(Math.sqrt(alist.length * 2))
    let mixList = _.shuffle(alist).concat(_.shuffle([...alist]))
    return _.chunk(mixList, boardSize)
}


let makeBlankBoard = (n) => Array(n).fill(null).map((x, i) => Array(n).fill(null))
let getEmojiCount = (boardSize) => Math.floor((boardSize ** 2) / 2)



export default function MemoryGame () {
    
    // width/height even numbers
    let [boardSize, setBoardSize] = useState(6)

    // the 2D array containing the emojis
    let [emojiBoard, setEmojiBoard] = useState(getBoard(getEmojiList(getEmojiCount(boardSize))))

    // the 2D array kept for tracking guesses
    let [solved, setSolved] = useState(makeBlankBoard(boardSize))

    // the "first" step coordinates (set for second click check)
    let [secondStep, setSecondStep] = useState(null)

    // game started toggle
    let [gameStarted, setGameStarted] = useState(false);

    let [gameWon, setGameWon] = useState(false);

    let [time, setTime] = useState(0);

    let timerRef = useRef()

    useEffect(() => {
        if (gameStarted) {
            timerRef.current = setInterval(
                () => {
                    setTime((time) => time + 10)
                },
                10
            )
        } else {
            clearInterval(timerRef.current)
        }
        return () => {
            clearInterval(timerRef.current)
        }
    }, [gameStarted])


    let handleBoardSizeChange = (event) => {
        event.preventDefault()
        if (gameStarted) return
        let newBoardSize = Number(event.target.value)
        setBoardSize(newBoardSize)
        setSolved(makeBlankBoard(newBoardSize))
        setEmojiBoard(getBoard(getEmojiList(getEmojiCount(newBoardSize))))
    }

    let startGame = (e) => {
        e.preventDefault()
        if (gameStarted) return
        setGameStarted(true)
    }

    let isExact = (a, b) => emojiBoard[a[0]][a[1]] === emojiBoard[b[0]][b[1]]

    let doHide = (j, i) => {
        if (!gameStarted) return 1
        if (solved[j][i]) return 1
        return 0
    }

    let handleTileClick = (j, i, event) => {
        event.preventDefault()
        if (!gameStarted) return
        if (secondStep) {
            if (secondStep[0] == j && secondStep[1] == i) return
            if (isExact(secondStep, [j, i])) {
                // match, set solved, unset secondStep
                let newSolved = [...solved]
                newSolved[j][i] = true
                setSolved(newSolved)
                setSecondStep(null)
                if (_.every(solved, _.every)) {
                    setGameWon(true)
                    clearInterval(timerRef.current)
                }
            } else {
                let newSolved = [...solved]
                newSolved[secondStep[0]][secondStep[1]] = null
                setSolved(newSolved)
                // no match, remove both from solved, unset secondStep
                setSecondStep(null)
            }
        } else {
            // first step/click
            if (solved[j][i]) return
            let newSolved = [...solved]
            newSolved[j][i] = true
            setSolved(newSolved)
            setSecondStep([j, i])
        }
    }
    return (
        <Container className="applet-container">
            <div className="memory-game">
                {gameWon ? "Nice job!" : (gameStarted ? "Go!" : "Ready?")}
                <br/>
                Time:
                <div className="timer">
                    <span className="digits">
                        {("0" + Math.floor((time / 60000) % 60)).slice(-2)}:
                    </span>
                    <span className="digits">
                        {("0" + Math.floor((time / 1000) % 60)).slice(-2)}.
                    </span>
                    <span className="digits mili-sec">
                        {("0" + ((time / 10) % 100)).slice(-2)}
                    </span>
                </div>
                <div className="memory-controls">
                    <div
                        className="controls"
                        style={{
                            fontSize: '10vw',
                            opacity: (gameStarted ? '0.2' : '0.5')
                        }}
                        onClick={startGame}
                    >
                    ▶️
                    </div>
                    <div
                        className="controls"
                        style={{
                            fontSize: '10vw',
                        }}
                        onClick={(e) => {
                            setEmojiBoard(getBoard(getEmojiList((boardSize ** 2) / 2)))
                            setGameStarted(false)
                            setSolved(makeBlankBoard(boardSize))
                            setTime(0)
                            setGameWon(false)
                        }}
                    >
                    🔄
                    </div>
                    Board size:
                    <input
                        type="range"
                        step="2"
                        min={4}
                        max={10}
                        value={boardSize}
                        onChange={handleBoardSizeChange}
                    ></input>
                </div>
                <div className={"gameboard " + (gameWon ? 'winner' : '')}>
                    {Array(boardSize).fill(null).map((x, i) => (
                        <div className="rowthing" key={i}>
                            {Array(boardSize).fill(null).map((y, j) => (
                                <div
                                    className="tile"
                                    key={j}
                                    onClick={_.partial(handleTileClick, j, i)}
                                >
                                    <div className="tile-content" style={{opacity: doHide(j, i)}}>
                                        {String.fromCodePoint(emojiBoard[j][i])}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    )
}
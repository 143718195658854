import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha'
import { getDeviceInfo, getWindowState } from '../utils';
import backendConfig from '../backend';
import isEmail from 'validator/lib/isEmail'

import 'react-phone-number-input/style.css'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import _ from 'lodash';

import Container from 'react-bootstrap/Container';


let initState = {
    name:  null,
    email: null,
    phone: null,
    note:  null,
}

let ContactMe = () => {
    let [captchaValue, setCaptchaValue] = useState(null)
    let [formData, setFormData] = useState(initState)
    let [submissionAccepted, setSubmissionAccepted] = useState(false)
    let [validated, setValidated] = useState(false)
    let [error, setError] = useState(null)
    let formRef = useRef(null)

    const validateContent = (form, state) => {
        return (
            _.every(state)
                && (form.checkValidity() !== false)
                && isEmail(state.email)
                && isValidPhoneNumber(state.phone)
                && (state.note.length >= 32)
                && captchaValue
        )
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        let form = event.currentTarget
        if (!validateContent(form, formData)) {
            event.stopPropagation()
            setValidated(true)
        }
        else {
            if (captchaValue) {
                fetch(backendConfig.contact, {
                    method: 'POST',
                    body: JSON.stringify({
                        formData,
                        captchaValue,
                        windowState: getWindowState(),
                        deviceDetect: getDeviceInfo(),
                    }),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin':  process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : 'https://jedmonson.net',
                        'Access-Control-Allow-Methods': 'POST',
                        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                    },
                }).then(
                    (response) => (response.json())
                ).then((data)=> {
                    if (data.success) {
                        setFormData(initState)
                        setSubmissionAccepted(data.successMessage)
                    } else {
                        setError(data.failureMessage)
                    }
                })
            } else {
                alert('Please complete the recaptcha.');
            }
        }
    };

    const setFormState = (e) => {
        let {name, value} = e.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    let getPhoneClass = (phone) => {
        if (phone) {
            if (isValidPhoneNumber(phone)) return "form-control is-valid"
            else return "form-control is-invalid"
        }
        if (validated) return "form-control is-invalid"
        return ""
    }

    let genForm = (state) => {
        return (
            <Form
                ref={formRef}
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
            >
                <Form.Group className="mb-3" controlId="contactName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        required
                        name="name"
                        placeholder="Jane Doe"
                        minLength={4}
                        maxLength={32}
                        onChange={setFormState}
                        value={state.name || ''}
                        isValid={state.name && state.name.length > 4 && state.name.length < 32}

                    />
                    <Form.Control.Feedback>Nice to meet you.</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Please enter your first & last name.</Form.Control.Feedback>
                </Form.Group>
    
                <Form.Group className="mb-3" controlId="contactEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                        required
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        minLength={7}
                        maxLength={128}
                        onChange={setFormState}
                        isValid={state.email && isEmail(state.email)}
                        value={state.email || ''}
                    />
                    <Form.Control.Feedback>Thank you.</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Please enter your email address.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="contactPhone">
                    <Form.Label>Phone Number</Form.Label>
                    <PhoneInput
                        name="phone"
                        defaultCountry='US'
                        placeholder="Enter phone number"
                        minLength={7}
                        maxLength={15}
                        value={state.phone || ''}
                        onChange={(v) => setFormData({...state, phone: v})}
                        className={getPhoneClass(state.phone)}
                    />
                    <Form.Control.Feedback>✅</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Please enter a valid phone number.</Form.Control.Feedback>
                </Form.Group>
        
                <Form.Group className="mb-3" controlId="requestNote">
                    <Form.Label>Request / Notes:</Form.Label>
                    <Form.Control
                        required
                        as="textarea"
                        minLength={32}
                        maxLength={300}
                        rows={3}
                        placeholder="...type something here."
                        name="note"
                        onChange={setFormState}
                        value={state.note || ''}
                        isValid={state.note && (state.note.length >= 32) && (state.note.length <= 300)}
                        isInvalid={state.note ? (state.note.length < 32 || state.note.length > 300) : false}
                    />
                    <Form.Control.Feedback>✅</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">Minimum 32 characters.</Form.Control.Feedback>
                    <div>{state.note ? state.note.length : 0} / 300</div>
                    
                </Form.Group>
                <Form.Group>
                    <Form.Label>Verify:</Form.Label>
                    <ReCAPTCHA
                        sitekey={backendConfig.gsitekey}
                        onChange={(v) => setCaptchaValue(v)}
                    />
                </Form.Group>
                <Button
                    variant={validateContent(formRef.current, state) ? "success" : "danger"}
                    type="submit"
                >
                    Submit
                </Button>
                <br/>
                {error ? (<error><strong>{error}</strong></error>) : null}
            </Form>
        )
    }
    return (
        <Container className="applet-container">
            <p>For business inquiries...</p>
            {!submissionAccepted ? genForm(formData) : <div style={{justifyContent: 'center', textAlign: 'center'}}>{submissionAccepted}</div>}
        </Container>
    )
}

export default ContactMe;

import React, { Component } from 'react'
import greetingsList from './greetings.json'


class Greeting extends Component {
  constructor(props) {
    super(props)
    this.state = { i: 0, duration: 1000 }
    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this)
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this)
  }
  componentDidMount() {
    this.interval = setInterval(
      () => this.setState({ i: (this.state.i + 1) % greetingsList.length }),
      this.state.duration
    )

  }
  onMouseEnterHandler() {
    clearInterval(this.interval)
  }
  onMouseLeaveHandler() {
    this.interval = setInterval(
      () => this.setState({ i: (this.state.i + 1) % greetingsList.length }),
      this.state.duration
    )
  }
  componentWillUnmount() {
    clearInterval(this.interval)
  }
  render() {
    return (
      <div onMouseEnter={this.onMouseEnterHandler}
        onMouseLeave={this.onMouseLeaveHandler}>
        <i>{greetingsList[this.state.i]}</i>
        <br />
        <span style={{ justifyContent: 'right' }}>{this.props.host}</span>
      </div>
    )
  }
}

export default Greeting

import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import themes from './themes.json'
import { useTheme } from './themes';

function* genFibs(n) {
  for (var i = 0, a = 0, b = 1; i < n; i++) {
    yield a;
    let t = a + b
    a = b
    b = t
  }
}

function* genCollatz(n) {
  while (n !== 1) {
    yield n
    n = n % 2 === 0 ? n / 2 : n * 3 + 1
  }
}

let SequenceApp = () => {
  let [inputN, setInputN] = useState(32)
  let [outArray, setOutArray] = useState({fibonacci: [], collatz: []})
  let [logScaleBit, setLogScale] = useState(false)
  let { theme } = useTheme();
  let currentTheme = themes[theme]
  useEffect(() => {
    setOutArray({fibonacci: [], collatz: []})
    for (let j of genFibs(inputN)) {
      setOutArray(prev => ({...prev, fibonacci: [...prev.fibonacci, j]}))
    }
    for (let i = 1; i <= inputN; i++) {
      let tmp = [...genCollatz(i)]
      setOutArray(prev => ({...prev, collatz: [...prev.collatz, tmp.length]}))
    }
  }, [inputN])
  return (
    <Container className="applet-container">
      <p>
        Graph the integers for the Fibonacci numbers (<a target="_blank" rel="noreferrer" href={"https://oeis.org/A000045"} >A000045</a>) 
        and the Collatz step sequence (<a target="_blank" rel="noreferrer" href={"https://oeis.org/A006577"} >A006577</a>).
      </p>
      <br/>
      <Row>
        Integer (N):&nbsp;<input
          type='number'
          value={inputN}
          onChange={(event) => {
            setInputN(event.target.value ? parseInt(event.target.value) : 1)
          }}
        />
        &nbsp;
        &nbsp;
        &nbsp;
        &nbsp;
        <button onClick={() => setOutArray([])}>Clear</button>
        <br/>
        <br/>
        Log Scale:&nbsp;<input type="checkbox" onChange={() => setLogScale(~logScaleBit)}></input>
        <br/>
        <br/>
      </Row>
      <Row>
        <Line
          data={{
            labels: Array(inputN).fill().map((x, i) => i),
            datasets: [
              {
                label: 'Fibonacci',
                data: outArray.fibonacci,
                borderColor: currentTheme.color3,
                backgroundColor: currentTheme.color3,
                yAxisID: 'y',
              },
              {
                label: 'Collatz',
                data: outArray.collatz,
                borderColor: currentTheme.color2,
                backgroundColor: currentTheme.color2,
                yAxisID: 'y1',
              },
            ],
          }}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Sequence',
              },
            },
            scales: {
              y: {
                type: logScaleBit ? 'logarithmic': 'linear',
                display: true,
                position: 'left'
              },
              y1: {
                type: logScaleBit ? 'logarithmic': 'linear',
                display: true,
                position: 'right'
              },
              y2: {
                type: logScaleBit ? 'logarithmic': 'linear',
                display: true,
                position: 'middle'
              }
            }
          }}
        />
      </Row>
    </Container>
  )
}

export default SequenceApp
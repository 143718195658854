import Container from 'react-bootstrap/Container'
import './oscredit.css'

let defaultFavicon = 'https://assets.readthedocs.org/images/favicon.f231b6609d0b.png'

/* eslint-disable no-return-assign, no-param-reassign */
// fmt off
let technologies = [
    { name: 'Python',               favicon: 'https://www.python.org/static/favicon.ico',                                                        homepage: 'https://www.python.org/'                                },
    { name: 'requests',             favicon: null,                                                                                               homepage: 'https://github.com/psf/requests'                        },
    { name: 'NodeJS',               favicon: 'https://nodejs.org/static/images/favicons/favicon.png',                                            homepage: 'https://nodejs.org/'                                    },
    { name: 'Lodash',               favicon: 'https://lodash.com/icons/favicon-32x32.png',                                                       homepage: 'https://lodash.com/'                                    },
    { name: 'pydash',               favicon: null,                                                                                               homepage: 'https://github.com/dgilland/pydash'                     },
    { name: 'Wireshark',            favicon: 'https://www.wireshark.org/assets/icons/favicon.ico',                                               homepage: 'https://www.wireshark.org/'                             },
    { name: 'Ubuntu',               favicon: 'https://assets.ubuntu.com/v1/49a1a858-favicon-32x32.png',                                          homepage: 'https://ubuntu.com/'                                    },
    { name: 'Arch Linux',           favicon: 'https://archlinux.org/static/favicon.29302f683ff8.ico',                                            homepage: 'https://archlinux.org/'                                 },
    { name: 'Scrapy',               favicon: 'https://scrapy.org/favicons/favicon-96x96.png',                                                    homepage: 'https://scrapy.org/'                                    },
    { name: 'RQ',                   favicon: 'https://python-rq.org/favicon.png',                                                                homepage: 'https://python-rq.org/'                                 },
    { name: 'Redis',                favicon: 'https://redis.io/wp-content/themes/wpx/assets/images/favicons/favicon.ico',                        homepage: 'https://redis.io/'                                      },
    { name: 'MongoDB',              favicon: 'https://www.mongodb.com/assets/images/global/favicon.ico',                                         homepage: 'https://www.mongodb.com/'                               },
    { name: 'dateparser',           favicon: null,                                                                                               homepage: 'https://github.com/scrapinghub/dateparser'              },
    { name: 'jq',                   favicon: 'https://jqlang.github.io/jq/icon.svg',                                                             homepage: 'https://github.com/jqlang/jq'                           },
    { name: 'up',                   favicon: 'https://raw.githubusercontent.com/akavel/up/master/logo.svg',                                      homepage: 'https://github.com/akavel/up'                           },
    { name: 'React',                favicon: 'https://react.dev/favicon.ico',                                                                    homepage: 'https://react.dev/'                                     },
    { name: 'pfSense',              favicon: 'https://docs.netgate.com/pfsense/en/latest/_static/favicon.ico',                                   homepage: 'https://www.pfsense.org/'                               },
    { name: 'gspread',              favicon: null,                                                                                               homepage: 'https://github.com/burnash/gspread'                     },
    { name: 'BeautifulSoup',        favicon: 'https://www.crummy.com/favicon.ico',                                                               homepage: 'https://www.crummy.com/software/BeautifulSoup/'         },
    { name: 'Debian',               favicon: 'https://www.debian.org/favicon.ico',                                                               homepage: 'https://www.debian.org/'                                },
    { name: 'GNU',                  favicon: 'https://www.gnu.org/graphics/gnu-head-mini.png',                                                   homepage: 'https://www.gnu.org/'                                   },
    { name: 'SciPy',                favicon: 'https://scipy.org/images/favicon.ico',                                                             homepage: 'https://scipy.org/'                                     },
    { name: 'Scapy',                favicon: 'https://scapy.net/favicon.ico',                                                                    homepage: 'https://scapy.net/'                                     },
    { name: 'netdata',              favicon: 'https://www.netdata.cloud/favicon-32x32.png',                                                      homepage: 'https://www.netdata.cloud/'                             },
    { name: 'Docker',               favicon: 'https://www.docker.com/wp-content/uploads/2024/02/cropped-docker-logo-favicon-192x192.png',        homepage: 'https://www.docker.com/'                                },
    { name: 'Nmap',                 favicon: 'https://nmap.org/shared/images/tiny-eyeicon.png',                                                  homepage: 'https://nmap.org/favicon/'                              },
    { name: 'Shinobi',              favicon: 'https://shinobi.video/assets/img/favicon.ico',                                                     homepage: 'https://shinobi.video/'                                 },
    { name: 'RabbitMQ',             favicon: 'https://www.rabbitmq.com/img/rabbitmq-logo.svg',                                                   homepage: 'https://www.rabbitmq.com/'                              },
    { name: 'PostgreSQL',           favicon: 'https://www.postgresql.org/favicon.ico',                                                           homepage: 'https://www.postgresql.org/'                            },
    { name: 'NumPy',                favicon: 'https://numpy.org/images/favicon.ico',                                                             homepage: 'https://numpy.org/'                                     },
    { name: 'pandas',               favicon: 'https://pandas.pydata.org/static/img/favicon.ico',                                                 homepage: 'https://pandas.pydata.org/'                             },
    { name: 'pyenv',                favicon: null,                                                                                               homepage: 'https://github.com/pyenv/pyenv'                         },
    { name: 'cheerio',              favicon: 'https://cheerio.js.org/img/favicon.ico',                                                           homepage: 'https://cheerio.js.org/'                                },
    { name: 'playwright',           favicon: 'https://playwright.dev/img/playwright-logo.svg',                                                   homepage: 'https://playwright.dev/'                                },
    { name: 'FeathersJS',           favicon: 'https://feathersjs.com/favicon.ico',                                                               homepage: 'https://feathersjs.com/'                                },
    { name: 'ExpressJS',            favicon: 'https://expressjs.com/images/favicon.png',                                                         homepage: 'https://expressjs.com/'                                 },
    { name: 'curl',                 favicon: 'https://curl.se/favicon.ico',                                                                      homepage: 'https://curl.se/'                                       },
    { name: 'OpenWRT',              favicon: 'https://openwrt.org/_media/favicon.ico',                                                           homepage: 'https://openwrt.org/'                                   },
    { name: 'Arduino',              favicon: 'https://www.arduino.cc/favicon.ico',                                                               homepage: 'https://www.arduino.cc/'                                },
    { name: 'Raspberry Pi',         favicon: 'https://assets.raspberrypi.com/favicon.png',                                                       homepage: 'https://www.raspberrypi.com/'                           },
    { name: 'Javascript',           favicon: "https://img.icons8.com/color/48/javascript--v1.png",                                               homepage: 'https://www.javascript.com/'                            },
    { name: 'Futil',                favicon: 'https://smartprocure.github.io/futil-js/icon.svg',                                                 homepage: 'https://github.com/smartprocure/futil-js'               },
    { name: 'VScode',               favicon: 'https://code.visualstudio.com/favicon.ico',                                                        homepage: 'https://code.visualstudio.com/'                         },
    { name: 'PyCharm',              favicon: 'https://upload.wikimedia.org/wikipedia/commons/1/1d/PyCharm_Icon.svg',                             homepage: 'https://www.jetbrains.com/pycharm/'                     },
    { name: 'ntop',                 favicon: 'https://www.ntop.org/wp-content/uploads/2015/04/favicon-ntop.png',                                 homepage: 'https://www.ntop.org/'                                  },
    { name: 'selenium',             favicon: 'https://www.selenium.dev/favicons/favicon.ico',                                                    homepage: 'https://www.selenium.dev/'                              },
    { name: 'Chart.js',             favicon: 'https://www.chartjs.org/favicon.ico',                                                              homepage: 'https://www.chartjs.org/'                               },
    { name: 'GitHub',               favicon: 'https://github.githubassets.com/favicons/favicon-dark.svg',                                        homepage: 'https://github.com/'                                    },
    { name: 'git',                  favicon: 'https://git-scm.com/favicon.ico',                                                                  homepage: 'https://git-scm.com/'                                   },
    { name: 'Bootstrap',            favicon: 'https://getbootstrap.com/docs/5.3/assets/img/favicons/favicon.ico',                                homepage: 'https://getbootstrap.com/'                              },
    { name: 'Faker-js',             favicon: 'https://fakerjs.dev/logo.svg',                                                                     homepage: 'https://fakerjs.dev/'                                   },
    { name: 'PyPI',                 favicon: 'https://pypi.org/static/images/favicon.35549fe8.ico',                                              homepage: 'https://pypi.org/'                                      },
    { name: 'fake-useragent',       favicon: 'https://avatars.githubusercontent.com/u/117346332?s=200&v=4',                                      homepage: 'https://github.com/fake-useragent/fake-useragent'       },
    { name: 'yt-dlp',               favicon: 'https://avatars.githubusercontent.com/u/79589310?s=200&v=4',                                       homepage: 'https://github.com/yt-dlp/yt-dlp'                       },
    { name: 'motionEye',            favicon: 'https://avatars.githubusercontent.com/u/101178162?s=200&v=4',                                      homepage: 'https://github.com/motioneye-project/motioneye'         },
    { name: 'fx',                   favicon: 'https://fx.wtf/img/favicons/favicon.ico',                                                          homepage: 'https://fx.wtf/'                                        },
    { name: 'Django',               favicon: 'https://static.djangoproject.com/img/icon-touch.e4872c4da341.png',                                 homepage: 'https://www.djangoproject.com/'                         },
    { name: 'Flask',                favicon: 'https://flask.palletsprojects.com/en/3.0.x/_static/shortcut-icon.png',                             homepage: 'https://flask.palletsprojects.com/'                     },
    { name: 'React Bootstrap',      favicon: 'https://react-bootstrap.netlify.app/img/favicon.ico',                                              homepage: 'https://github.com/react-bootstrap/react-bootstrap'     },
    { name: 'Chromium',             favicon: 'https://www.chromium.org/_assets/icon-chromium-96.png',                                            homepage: 'https://www.chromium.org/'                              },
    { name: 'Jazzband',             favicon: 'https://jazzband.co/static/favicons/favicon-96x96.png',                                            homepage: 'https://jazzband.co/'                                   },
    { name: 'SQLite',               favicon: 'https://www.sqlite.org/favicon.ico',                                                               homepage: 'https://www.sqlite.org/'                                },
    { name: 'blender',              favicon: 'https://www.blender.org/favicon.ico',                                                              homepage: 'https://www.blender.org/'                                },
]
/* eslint-enable no-return-assign, no-param-reassign */
// fmt on

let OSCredit = () => {
    return (
        <Container id="creds">
            <p>This developer is privileged to use and gives thanks to many freely available technologies:</p>
            <br />
            <div className="ul">
                {technologies.map((x, n) => (
                    <div key={n} className="li">
                        <a target="_blank" rel="noreferrer" href={x.homepage}><img alt={""} src={x.favicon || defaultFavicon} />&nbsp;&nbsp;{x.name}</a>
                    </div>
                ))}
            </div>
            <br />
            <blockquote style={{ color: "rgb(80, 80, 80)" }}>
                "...If I have seen further, it is by standing on the shoulders of Giants."<br /><cite>-Isaac Newton</cite>
            </blockquote>
        </Container>
    )
}


export default OSCredit;

import { useState } from 'react'
import { crossCheck, jsonCodeBlock } from '../utils'  // jsonPretty
import Ajv from 'ajv'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import themes from './themes.json'
import { Tree } from 'react-tree-graph'
import _ from 'lodash/fp'
import {useTheme} from './themes'
import './jsonSchemaView.css'

let ajv = new Ajv()

let defaultSchema = {
  "title": "Person",
  "type": "object",
  "properties": {
    "firstName": {
      "type": "string",
      "description": "The person's first name."
    },
    "lastName": {
      "type": "string",
      "description": "The person's last name."
    },
    "age": {
      "description": "Age in years which must be equal to or greater than zero.",
      "type": "integer",
      "minimum": 0
    },
    "address": {
      "description": "The address of the person",
      "type": "object",
      "properties": {
        "dwellingNumber": {
          "type": "number",
          "description": "Dwelling/Unit number"
        },
        "streetName": {
          "type": "string"
        },
        "city": {
          "type": "string",
        },
        "state": {
          "type": "object",
          "properties": {
            "fipscode": {
              "type": "number"
            },
            "abbreviation": {
              "type": "string"
            }
          }
        }
      }
    }
  }
}

let schemaTransform = (node) => {
  let name
  if (_.isArray(node)) {
    [name, node] = node
  } else {
    name = node.title
  }
  let result = { name }
  let children = _.flow(_.get('properties'), _.toPairs)(node).map(schemaTransform)
  if (!_.isEmpty(children)) {
    result.children = children
  }
  return result
}


let isValidJson = (inputStr) => {
  try {
    JSON.parse(inputStr)
    return true
  } catch (err) {
    return false
  }
}

let validateSchema = (inputStr) => {
  try {
    return ajv.validateSchema(JSON.parse(inputStr))
  } catch (err) {
    return false
  }
}

let SchemaViewer = () => {
  let { theme } = useTheme()
  let currentTheme = themes[theme]
  let [schemaInput, setSchemaInput] = useState(JSON.stringify(defaultSchema, undefined, 2))
  let [schemaErrors, setSchemaErrors] = useState({})
  let [schema, setSchema] = useState(defaultSchema)
  let updateSchema = (inputStr) => {
    setSchemaInput(inputStr)
    if (!inputStr.trim()) return
    if (!isValidJson(inputStr)) return
    if (!ajv.validateSchema(JSON.parse(inputStr))) {
      let errors = ajv.errors
      if (errors) {
        setSchemaErrors(errors)
      }
      return
    }
    setSchemaErrors({})
    setSchema(JSON.parse(inputStr))
  }
  return (<Container className="applet-container">
    <p>
        Useful in validating, visualizing, and building a <a target="_blank" rel="noreferrer" href={"https://schema.org/"}>JSON schema</a> definition. 
        Paste-in or edit the schema below to alter the tree nodes & find <a target="_blank" rel="noreferrer" href={"https://ajv.js.org/"}>Ajv</a> validation errors.
    </p>
    <Col>
      <Row>
        <Col>
          JSON Object valid: {crossCheck(isValidJson(schemaInput))}
          <br/>
          JSON schema valid (Ajv): {crossCheck(validateSchema(schemaInput))}
        </Col>
        <Col>
          JSON Schema Errors:
          {jsonCodeBlock(schemaErrors)}
        </Col>
      </Row>
      <Row>
        <textarea
          style={{
            width: '100%',
            height: '70vh',
            backgroundColor: currentTheme.background,
            color: currentTheme.cursor,
           }}
          spellCheck={false}
          value={schemaInput}
          onChange={(e) => updateSchema(e.target.value)}
        />
      </Row>
      <Row>
        <Tree
          svgProps={{ transform: "rotate(90)" }}
          data={schemaTransform(schema)}
          height={1000}
          width={800}
        />
      </Row>
    </Col>
  </Container>)
}

export default SchemaViewer

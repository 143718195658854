import './imageBoard.css'
import _ from 'lodash'


let images = [
    {src: '/pics/dryTortugas/1.jpg',},
    {src: '/pics/dryTortugas/2.jpg',},
    {src: '/pics/dryTortugas/3.jpg',},
    {src: '/pics/dryTortugas/4.jpg',},
    {src: '/pics/dryTortugas/5.jpg',},
    {src: '/pics/monterey/1.jpg',},
    {src: '/pics/monterey/2.jpg',},
    {src: '/pics/monterey/3.jpg',},
    {src: '/pics/monterey/4.jpg',},
    {src: '/pics/monterey/5.jpg',},
    {src: '/pics/philmont/1.jpg',},
    {src: '/pics/philmont/8.jpg',},
    {src: '/pics/philmont/9.jpg',},
    {src: '/pics/philmont/2.jpg',},
    {src: '/pics/philmont/3.jpg',},
    {src: '/pics/philmont/4.jpg',},
    {src: '/pics/philmont/5.jpg',},
    {src: '/pics/philmont/6.jpg',},
    {src: '/pics/philmont/7.jpg',},
    {src: '/pics/yosemite/1.jpg',},
    {src: '/pics/yosemite/2.jpg',},
    {src: '/pics/yosemite/3.jpg',},
]


export default function ImageBoard () {
    return (
        <>
            <div className="imageGrid">
                {_.shuffle(images).map((src) => (
                    <img
                        alt="src"
                        {...src}
                    />
                ))}
            </div>
        </>
    )
}
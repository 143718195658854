import { useState, useEffect } from 'react';
import _ from 'lodash/fp'
import { HuePicker } from 'react-color'
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



let BinaryView = () => {
  let alpha = 255
  let [nullColor, setNullColor] = useState({r: 128, g: 128, b: 128, a: 255})
  let [controlColor, setControlColor] = useState({r: 255, g: 0, b: 255, a: 255})
  let [asciiColor, setAsciiColor] = useState({r: 0, g: 255, b: 0, a: 255})
  let [extendedAsciiColor, setExtendedAsciiColor] = useState({r: 0, g: 0, b: 255, a: 255})
  let [otherColor, setOtherColor] = useState({r: 255, g: 0, b: 0, a: 255})


  let [files, setFiles] = useState([])

  let [canvasRefs, setCanvasRefs] = useState([])
  let width = 64

  let colorState = {
    'other': otherColor,
    'ascii': asciiColor,
    'extendedAscii': extendedAsciiColor,
    'control': controlColor,
    'null': nullColor
  }

  let getColor = (b) =>{
    if (b === 0) {
      return colorState.null
    } else if (b < 32 || b === 127) {
      return colorState.control
    } else if (b < 128) {
      return colorState.ascii
    } else if (b >= 128) {
      return colorState.extendedAscii
    } else {
      return colorState.other
    }
  }

  let orderRGB = ({r, g, b}) => [r, g, b, alpha]

  let readFileUpload = (file) => {
    let reader = new FileReader()
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort()
        reject(new DOMException("FileReader problem..."))
      }
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.readAsArrayBuffer(file)
    })
  }

  let processLoad = async (event) => {
    if (!event.target || !event.target.files) {
      return
    }
    const fileList  = event.target.files
    for (let file of fileList) {
      let fileArray = await readFileUpload(file)
      let arr = new Uint8Array(fileArray)
      setFiles(
        prev => [
          ...prev,
          {
            data: arr,
            height: Math.ceil(arr.length / width),
            name: file.name
          }
        ]
      )
    }
  }

  useEffect(
    () => {
      for (let n = 0; n < files.length; n++) {
        let {data, height} = files[n]
        let mask = _.flatten(_.map(_.flow(getColor, orderRGB))(data))
        let context = canvasRefs[n].current.getContext("2d")
        context.clearRect(0, 0, context.width, context.height);
        if (data.length) {
          var imgData = context.createImageData(width, height)
          for (var i=0;i < mask.length; i += 4) {
            imgData.data[i]   = mask[i];   //red
            imgData.data[i+1] = mask[i+1]; //green
            imgData.data[i+2] = mask[i+2]; //blue
            imgData.data[i+3] = mask[i+3]; //alpha
          }
          context.putImageData(imgData, 0, 0)
        }
      }
    }, [files, nullColor, controlColor, asciiColor, extendedAsciiColor, otherColor]
  )

  return (
    <Container className="applet-container">
      <p>
        View a binary representation of file(s) based on each byte's value. 
        Adjust the color pickers for each character set. 
        Currently sets are null characters, control characters, ascii, extended ascii, and "other". 
        Your files never leave your device, the visualization is client-side rendered.
      </p>
      <br/>
      <Row>
        <Col>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Colors</Accordion.Header>
              <Accordion.Body>
                Null character (Codepoint 0): <HuePicker color={nullColor} onChangeComplete={(color) => setNullColor({...color.rgb, a: 255})}/>
                <br/>
                Control Characters: <HuePicker color={controlColor} onChangeComplete={(color) => setControlColor({...color.rgb, a: 255})}/>
                <br/>
                ASCII: <HuePicker color={asciiColor} onChangeComplete={(color) => setAsciiColor({...color.rgb, a: 255})}/>
                <br/>
                Extended ASCII: <HuePicker color={extendedAsciiColor} onChangeComplete={(color) => setExtendedAsciiColor({...color.rgb, a: 255})}/>
                <br/>
                Other: <HuePicker color={otherColor} onChangeComplete={(color) => setOtherColor({...color.rgb, a: 255})}/>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <br/>
          <br/>
          <input
            id="files"
            type="file"
            multiple={true}
            onChange={processLoad}
          />
          <br/>
          <br/>
          <button
            onClick={() => {
              setFiles([])
              setCanvasRefs([])
              document.getElementById('files').value = ""
            }}
          >Reset</button>
        </Col>
        <Col>
          <Row>
            {files.map((v, i) => (
              <Col id="canvas-container">
                <canvas
                  key={i}
                  ref={ref => {
                    canvasRefs[i] = {current: ref}
                  }}
                  height={v.height} width={width}
                  style={{border: '1px solid yellow'}}
                />
                &nbsp;&nbsp;&nbsp;
                <br/>
                {v.name}
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  )
}


export default BinaryView

import _ from 'lodash/fp';
import * as rdd from 'react-device-detect';
import F from 'futil'

export let jsonPretty = (value) => JSON.stringify(value, undefined, 2);

export let codeBlock = (value) => (
  <div className='code'>
    {value}
  </div>
)

export let jsonCodeBlock = _.flow(
  jsonPretty,
  codeBlock
)

export let crossCheck = (isBool) => (<>{isBool ? "✅" : "❌"}</>)


export let cleanNum = (num) => num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })

export let moneyString = (num) => `$ ${cleanNum(num)}`


export let getDeviceInfo = () => _.flow([
  _.pick(['osName', 'osVersion', 'getUA', 'mobileModel', 'mobileVendor', 'browserName', 'fullBrowserVersion']),
  F.renameProperty('getUA', 'userAgent'),
  _.pickBy((v) => v !== 'none')
])(rdd)

export let getWindowState = () => ({
  zoom: Math.floor((( window.outerWidth - 10 ) / window.innerWidth) * 100),
  height: window.innerHeight,
  width: window.innerWidth,
})

let currentEnv = process.env.NODE_ENV || "production"
let backendDomain = currentEnv === "development" ? "localhost:5000" : 'api.jedmonson.net'
let gsitekey = '6LcTUrYZAAAAANBFMWjKuJjsnBalSwk0N87GXI4q'

let protocol = currentEnv === "development" ? "http" : "https"

let backendConfig = {
    echo: `${protocol}://${backendDomain}/echo`,
    contact: `${protocol}://${backendDomain}/contact`,
    gsitekey,
}

export default backendConfig;

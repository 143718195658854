import { jsonCodeBlock, getDeviceInfo, getWindowState } from '../utils';
import { useState } from 'react';
import Container from 'react-bootstrap/Container'


let ShowClientInfo = ({host}) => {
  let [windowState] = useState(getWindowState());

  // below makes Chart.JS rendering really slow
  // TODO: find a way to update this without causing the graph updates to be really slow.
  // setInterval(() => setWindowState(getWindowState()), 250)
  // TODO: make it look nice with icons/images, geo locate the host, etc.

  return (
    <Container className="applet-container">
      <p>Details of the browser being used to access this application.</p>
      <div id='client-info'>
        {jsonCodeBlock({
          window: windowState,
          clientInfo: {...getDeviceInfo(), ip: (host === "Friend" ?  null : host)}
        })}
      </div>
    </Container>
    
  )
}

export default ShowClientInfo

import React, { createContext, useContext, useState, useEffect } from 'react';
import themes from './themes.json'
import './themes.css'


const ThemeContext = createContext();


const useDynamicStyles = (stylesObject) => {
    useEffect(() => {
      const styleSheet = document.createElement('style');
      styleSheet.type = 'text/css';
  
      let styles = '';
      for (const selector in stylesObject) {
        styles += `${selector} {`;
        for (const property in stylesObject[selector]) {
          const value = stylesObject[selector][property];
          styles += `${property}: ${value};`;
        }
        styles += '} ';
      }
  
      styleSheet.innerText = styles;
      document.head.appendChild(styleSheet);
  
      return () => {
        document.head.removeChild(styleSheet);
      };
    }, [stylesObject]);
  };


export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(
        Object.keys(themes)[Math.floor(Math.random() * Object.keys(themes).length)]
    )
    const currentTheme = themes[theme]
    useEffect(() => {
        document.body.style.backgroundColor = currentTheme.background;
        return () => {
            document.body.style = {backgroundColor: currentTheme.background};
        }
    }, [currentTheme])
    useDynamicStyles({
        ".navbar-toggler-icon": {
            "background-color": currentTheme.foreground,
        },
        // src/index.js applet containers
        ".applet": {
            outline: `solid 1px ${currentTheme.color1}`,
        },
        // OS Credit
        "a": {
            color: currentTheme.color3
        },
        "a:hover": {
            color: currentTheme.color5,
        },
        // JSON Schema Viewer
        ".node circle,.node rect": {
            fill: currentTheme.color14,
            stroke: currentTheme.color14,
        },
        ".node text": {
            fill: currentTheme.color13
        },
        "path.link": {
            stroke: currentTheme.color12
        },
        "::selection": {
            "background-color": currentTheme.cursor
        },

        ".nav-link, .navbar-brand": {
            color: `${currentTheme.color9} !important;`
        },
        ".dropdown-menu": {
            "background-color": `${currentTheme.color0} !important;`
        },
        ".dropdown-menu > a": {
            color: `${currentTheme.foreground} !important;`
        }
    })
    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            <div
                style={{
                    backgroundColor: currentTheme.background,
                    color: currentTheme.foreground
                }}
            >
                {children}
            </div>
        </ThemeContext.Provider>
    )
}


export const useTheme = () => useContext(ThemeContext);


export default function ChangeTheme () {
    let { theme, setTheme } = useTheme();
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="themes-display">
                {Object.keys(themes).map((themeName) => {
                    let themeObj = themes[themeName]
                    let themeItemStyle = {}
                    if (themeName === theme) {
                        themeItemStyle['outline'] = "3px solid limegreen"
                    }
                    return (
                        <div
                            onClick={(e) => setTheme(themeName)}
                            style={{ backgroundColor: themeObj.background, ...themeItemStyle }}
                            key={themeName}
                            className="theme-item"
                        >
                            {Object.entries(themeObj).filter(([key, val]) => (key.match(/\d/))).map(([key, val]) => (
                                <div key={key} className="grid-block" style={{ backgroundColor: val }}></div>
                            ))}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

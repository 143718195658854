import { Routes, Route, Outlet, Link } from "react-router-dom";
import { useState, useEffect } from 'react'
import backendConfig from "../backend";

import Layout from "./layout";

import HomeAbout from './about'
import FrequencyApp from './frequencyChart'
import SequenceApp from './sequences'
import ShowClientInfo from './clientInfo'
import BinaryView from './binaryView'
import IncomeHousing from './housingAffordability'
import SchemaViewer from './jsonSchemaView'
import OSCredit from './oscredit'
import ContactMe from './contactMe'
import ChangeTheme from './themes'
import ImageBoard from './imageBoard'
import MemoryGame from './memoryGame'
import NoMatch from "./noMatch";


export default function NewApp() {
    let [host, setHost] = useState('Friend')

    useEffect(() => {
      fetch(
        backendConfig.echo
      ).then(
        (res) => res.json()
      ).then(
        (data) => setHost(data.ip)
      ).catch(
        (reason) => console.log(reason)
      )
    }, [])
    return (
      <Routes>
        <Route path="/" element={<Layout host={host}/>}>
            <Route index path="" element={<HomeAbout host={host} />} />
            <Route path="contact" element={<ContactMe />} />
            <Route path="photos" element={<ImageBoard />} />
            <Route path="memoryGame" element={<MemoryGame />} />
            <Route path="frequency-charts" element={<FrequencyApp />} />
            <Route path="sequences" element={<SequenceApp />} />
            <Route path="client" element={<ShowClientInfo host={host} />} />
            <Route path="binary" element={<BinaryView />} />
            <Route path="affordability" element={<IncomeHousing />} />
            <Route path="schema" element={<SchemaViewer />} />
            <Route path="credit" element={<OSCredit />} />
            <Route path="themes" element={<ChangeTheme />} />
            <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    )
}
import { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash/fp'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useTheme } from './themes'
import themes from './themes.json'

let countOccurences = (text, substring) => (text.match(new RegExp(substring, "g")) || []).length

let FrequencyApp = () => {
  let {theme} = useTheme();
  let currentTheme = themes[theme]
  let [freqText, setFreqText] = useState(
    "Enter text to inspect character frequency."
  )
  let [isAlphasOnly, setAlphasOnly] = useState(false)
  let [valueSortOrder, setValueSortOrder] = useState(false)
  
  let getFreqs = (text) => {
    text = text.toUpperCase()
    if (isAlphasOnly) {
      text = text.replace(/[^A-Z]/g, "")
    }
    let barLabels = Array.from(new Set(text)).sort()
    let result = barLabels.map(
      (character) => {
        let count = countOccurences(text, character.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&'))
        return {character, count, frequency: count / text.length}
      }
    )
    if (valueSortOrder) {
      result = result.sort((a, b) => b.frequency - a.frequency)
    }
    return {
      labels: result.map(x => `"${x.character}"`),
      datasets: [
        {
          label: 'Count',
          data: result.map(_.get('count')),
          borderColor: currentTheme.color4,
          backgroundColor: currentTheme.color4,
          yAxisID: 'y',
        },
        {
          label: 'Decimal',
          data: result.map(_.get('frequency')),
          borderColor: currentTheme.color5,
          backgroundColor: currentTheme.color5,
          yAxisID: 'y1',
        }
      ]
    }
  }

  let getBigrams = (text) => {
    let result = []
    let barLabels = []
    if (text.length >= 2) {
      text = text.toUpperCase().replace(/[^A-Z ]/g, "").replace(/\s+/g, " ")
      let bigrams = _.remove(
        (x) => _.includes(' ')(x) || _.includes(undefined)(x) || _.includes(null)(x)
      )(
        _.zip(Array.from(text), Array.from(text.slice(1)))
      ).map(x => x.join(''))
      
      barLabels = Array.from(new Set(bigrams)).sort()
      result = barLabels.map(
        (bigram) => {
          let count = countOccurences(text, bigram)
          return {bigram, count, frequency: count / bigrams.length}
        }
      )
      if (valueSortOrder) {
        result = result.sort((a, b) => b.frequency - a.frequency)
      }
    }
    return {
      labels: result.map((x) => `"${x.bigram}\n`),
      options: {
        plugins: {
          legend: {
            labels: {
              font: {
                color: currentTheme.foreground,
              }
            }
          }
        }
      },
      datasets: [
        {
          label: 'Count',
          data: result.map(_.get('count')),
          borderColor: currentTheme.color6,
          backgroundColor: currentTheme.color6,
          yAxisID: 'y',
        },
        {
          label: 'Decimal',
          data: result.map(_.get('frequency')),
          borderColor: currentTheme.color7,
          backgroundColor: currentTheme.color7,
          yAxisID: 'y1',
        }
      ]
    }
  }

  return (
    <Container className="applet-container">
      <p>Cryptanalyze the character & bigram frequency of a text string.</p>
      <br/>
      <Col>
        <Row>
          <Col>
            Length: {freqText.length}
            <br/>
            <br/>
            Unique characters: {Array.from(new Set(freqText)).length}
            <br/>
            <br/>
            Alphabetics only: <input type="checkbox" onChange={() => setAlphasOnly(~isAlphasOnly)}></input>
            <br/>
            <br/>
            Value order: <input type="checkbox" onChange={() => setValueSortOrder(~valueSortOrder)}></input>
            <br/>
            <br/>
            <button onClick={() => setFreqText('')}>Clear</button>&nbsp;
          </Col>
          <Col>
            <textarea
              style={{
                flex: 1,
                width: '100%',
                height: '10em',
                color: currentTheme.color10
              }}
              onClick={(e) => e.target.select()}
              placeholder={'Enter text to inspect character frequency'}
              value={freqText}
              onChange={(event) => setFreqText(event.target.value)} />
          </Col>
        </Row>
          <Row>
            <Container>
            <Row>
              <Bar data={getFreqs(freqText)} options={{
                scaleUse2Y: true,
                plugins: {
                  title: {
                    display: true,
                    text: 'Character frequency'
                  }
                },
                scales: {
                  y: {
                    type: 'linear',
                    display: true,
                    position: 'left'
                  },
                  y1: {
                    type: 'linear',
                    display: true,
                    position: 'right'
                  }
                }
              }}
              />
            </Row>
            <Row>
              <Bar data={getBigrams(freqText)} options={{
                plugins: {
                  title: {
                    display: true,
                    text: 'Bigrams'
                  }
                },
                scales: {
                  y: {
                    type: 'linear',
                    display: true,
                    position: 'left'
                  },
                  y1: {
                    type: 'linear',
                    display: true,
                    position: 'right'
                  }
                }
              }}/>
            </Row>
          </Container>
        </Row>
      </Col>
      
    </Container>
  )
}


export default FrequencyApp

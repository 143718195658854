import Greeting from './greet'
import { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import './about.css'


let HomeAbout = ({host}) => {
  let [popData, setPopData] = useState(null)
  useEffect(() => {
    fetch(
      'https://www.census.gov/popclock/data/population.php/world'
    ).then(
      res => res.json()
    ).then(
      setPopData
    ).catch(
      (err) => console.log(err)
    )
  }, [])
  return (
    <div className='applet-container'>
      <div id="blurb" className="text-center">
        <h2><Greeting host={host} /></h2>
        <div id="headshot"></div>
        <h3>Hi! My name is Jonathan Edmonson.</h3>
        <p>
          I am 1 of <a rel="noreferrer" href="https://www.census.gov/popclock/" target="_blank" >{popData ? popData.world.population.toLocaleString() : null}</a>
        </p>
      </div>
      <Row className="about-me">
        <Col md={1} lg={3} style={{marginLeft: "8em"}}>
          <strong>Interests:</strong>
          <ul id="interests">
            <li>STEM</li>
            <li>Security</li>
            <li>Finance</li>
            <li>Radio</li>
            <li>Software</li>
            <li>Hardware</li>
            <li>Networks</li>
          </ul>
        </Col>
        <Col md={1} lg={3} >
          <br/>
          <p className="text-center">
            Six years of professional experience in the financial technology industry. 
            Built dependable & scalable systems generating millions of dollars in subscription revenue. 
            Avid explorer, learner, tinkerer. 
            Get in touch to learn how I can help derive value from your data.
          </p>
        </Col>
        <Col md={1} lg={3} style={{marginLeft: "8em"}}>
          <strong>Services:</strong>
          <ul id="interests">
            <li>Web development</li>
            <li>Private hosting</li>
            <li>Data management</li>
            <li>Statistical analysis</li>
            <li>Web scraping</li>
            <li>Computer & server builds</li>
            <li>Graphic design</li>
          </ul>
        </Col>
      </Row>
      <br />
    </div>
  )
}

export default HomeAbout;

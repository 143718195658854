import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

import ChartJS from './charts.js'   // register objects charts.js

import { BrowserRouter } from 'react-router-dom';
import NewApp from './components/router.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <NewApp />
    </BrowserRouter>
  </React.StrictMode>
);

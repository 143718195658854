import { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container'
import * as d3 from 'd3'
import { pmt } from 'financial'
import { Chart } from 'react-chartjs-2'
import _ from 'lodash'
import { moneyString, cleanNum } from '../utils'

import './housingAffordability.css';


let hoverClickInstructions = (<tr><td colSpan={7}><pre style={{backgroundColor: "rgba(255, 255, 255, 0.5)"}}>(hover & click either grid to inspect details)</pre></td></tr>)

let stateSchemaMap = {
  monthlyIncome:     {initValue: 3600,   units: '$',          name: 'Monthly Income',        step: "50"},
  monthlyDebt:       {initValue: 500,    units: '$',          name: 'Monthly Debt',          step: "50"},
  percentDown:       {initValue: 0.20,   units: '%',          name: 'Percent Down',          step: "0.5", display: (x) => x * 100, setter: (x) => x / 100},
  nPayments:         {initValue: 360,    units: 'N payments', name: 'Number of Payments',    step: "5"},
  minDown:           {initValue: 20000,  units: '$',          name: 'Minimum Down',          step: "1000"},
  maxDown:           {initValue: 80000,  units: '$',          name: 'Maximum Down',          step: "1000"},
  dollarInterval:    {initValue: 1000,   units: '$',          name: 'Dollar Interval',       step: "1000"},
  minRate:           {initValue: 0.01,   units: '%',          name: 'Minimum Rate',          step: "0.01"},
  maxRate:           {initValue: 0.09,   units: '%',          name: 'Maximum Rate',          step: "0.01"},
  rateInterval:      {initValue: 0.005,  units: '%',          name: 'Rate Interval',         step: "0.001"},
}

let initialState = Object.fromEntries(
  Object.keys(stateSchemaMap).map(x => [x, stateSchemaMap[x].initValue])
)


let IncomeHousing = () => {
  let [state, setState] = useState(initialState)

  let [selected, setSelected] = useState([null, null])
  let [isHover, setIsHover] = useState(true)

  let downPayments = Array(
    Math.floor((state.maxDown - state.minDown) / state.dollarInterval)
  ).fill().map(
    (i, x) => (x * state.dollarInterval) + state.minDown
  )

  let principles = downPayments.map(
    (downPayment, x) => (downPayment * (1 / state.percentDown)) - downPayment
  )

  let interestRates = Array(
    Math.ceil((state.maxRate - state.minRate) / state.rateInterval)
  ).fill().map(
    (i, x) => (x * state.rateInterval) + state.minRate
  )

  let housingIncomeColors = d3.scaleQuantize()
    .domain([0.28, 0.3, 0.4])
    .range([
      '#44ce1b', '#bbdb44', '#fcdb03', '#e51f1f'
    ]);

  let colors = d3.scaleQuantize()
    .domain([0.36, 0.38, 0.5])
    .range([
      '#44ce1b', '#bbdb44', '#fcdb03', '#e51f1f'
    ]);

  let paymentMatrix = downPayments.map(
    (down, i) => interestRates.map(
      (interestRate, j) => pmt(interestRate / 12, state.nPayments, -(down * (1 / state.percentDown)))
    )
  )

  let housingIncomeMatrix = paymentMatrix.map(
    (x, i) => x.map((y, j) => y / state.monthlyIncome)
  )

  let debtIncomeMatrix = paymentMatrix.map(
    (x, i) => x.map((y, j) => (y + state.monthlyDebt) / state.monthlyIncome)
  )

  let amortize = ([i, j]) => {
    if (i === undefined) return []
    let principle = principles[i] + downPayments[i]
    let interestRate = interestRates[j]
    let monthlyRate = interestRate / 12
    let payment = paymentMatrix[i][j]
    let totalLoanCost = payment * state.nPayments
    let interest = totalLoanCost - principle

    let ret = []

    for (let n = 1; n < state.nPayments + 1; n++) {
      let interestPortion = principle * monthlyRate

      let principlePortion = payment - interestPortion

      ret.push({
        principle,
        paymentNumber: n,
        payment,
        interestRate,
        paid: payment * n,
        loanBalance: payment * (state.nPayments - n),
        interestBalance: interest,
        interestPortion,
        principleBalance: principle,
        principlePortion: principlePortion,
      })

      interest = interest - interestPortion
      principle = principle - principlePortion

    }
    return ret
  }

  let formatData = ([i, j]) => {
    let data = amortize([i, j]);
    return [
      {
        label: 'Interest portion',
        type: 'line',
        data: (
          _.isNumber(selected[0]) ? _.map(data, 'interestPortion') : null
        ),
        borderColor: `rgba(255, 0, 0, 1)`,
        backgroundColor: `rgba(255, 0, , 1)`,
        yAxisID: 'y1',
      },
      {
        label: 'Principle portion',
        type: 'line',
        data: (
          _.isNumber(selected[0]) ? _.map(data, 'principlePortion') : null
        ),
        borderColor: `rgba(0, 255, 0, 1)`,
        backgroundColor: `rgba(0, 255, 0, 1)`,
        yAxisID: 'y1',
      },
      {
        label: 'Interest',
        type: 'line',
        data: (
          _.isNumber(selected[0]) ? _.map(data, 'interestBalance') : null
        ),
        borderColor: `rgba(255, 0, 128, 1)`,
        backgroundColor: `rgba(255, 0, 128, 1)`,
      },
      {
        label: 'Principle',
        type: 'line',
        data: (
          _.isNumber(selected[0]) ? _.map(data, 'principleBalance') : null
        ),
        borderColor: `rgba(255, 255, 0, 1)`,
        backgroundColor: `rgba(255, 255, 0, 1)`,
      }
    ]
  }
  
  let formatResults = (x, y) => {
    return (
      <tr>
        <td>
          {/* Principle */}
          {_.isNumber(x) ? moneyString((1 / state.percentDown) * downPayments[x]) : null}
        </td>
        <td>
          {/* Down Payment */}
          {_.isNumber(x) ? moneyString(downPayments[x]) : null}
        </td>
        <td>
          {/* Interest Rate: */}
          {_.isNumber(x) ? `${cleanNum(interestRates[y] * 100)} %` : null}
        </td>
        <td>
          {/* Monthly Payment: */}
          {_.isNumber(x) ? moneyString(paymentMatrix[x][y]) : null}
        </td>
        <td>
          {/* Debt-to-Income Ratio: */}
          {_.isNumber(x) ? `${cleanNum(debtIncomeMatrix[x][y] * 100)} %` : null}
        </td>
        <td>
          {/* Housing-to-Income Ratio: */}
          {_.isNumber(x) ? `${cleanNum(housingIncomeMatrix[x][y] * 100)} %` : null}
        </td>
        <td>
          {/* Total Loan Cost: */}
          {_.isNumber(x) ? moneyString(paymentMatrix[x][y] * state.nPayments) : null}
        </td>
      </tr>
    )
  }

  return (
    <Container className="applet-container">
      <>
        <p>
          Visualize a curve of affordability for budgeting with a loan. This uses the "28 / 36 rule" to define affordability. 
          In that, housing costs shall not exceed 28% of gross income and all combined debt payments (including housing) shall not exceed 36%. 
        </p>
        <p>
          As of 2024, the NEA reports that the average monthly salary for new teachers in the US is about $3,600 (~$43K / year). 
          In the example below, a person pays various other forms of debt amounting to $500 every month. 
          This stands for the credit card debt, student, auto, and other personal loans. 
        </p>
        <p>
          Hover over & click the matrices below to display the total loan cost based on interest rates (matrix left to right), as well as the down payment (matrix up & down). 
          Adjust the settings to the left to increase granularity or view a different interest rate or down payment range. 
        </p>
      </>
      <div className="loans-app">
        <Row>
          <Col>
            <div style={{justifyContent: "center", textAlign: "center"}}>
              <h3>Grid selection</h3>
              
            </div>
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Principle</th>
                  <th>Down Payment</th>
                  <th>Interest Rate</th>
                  <th>Monthly Payment</th>
                  <th>Debt-to-Income Ratio</th>
                  <th>Housing-to-Income Ratio</th>
                  <th>Total Loan Cost</th>
                </tr>
              </thead>
              <tbody>
                {_.isNumber(selected[0]) ? formatResults(selected[0], selected[1]) : hoverClickInstructions}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col className="settings">
            <div style={{justifyContent: "space-between"}}>
              <h3>Settings</h3>
              <button onClick={() => {
                setState(initialState)
                setIsHover(true)
                setSelected([null, null])
              }}>Reset</button>
            </div>
            <Table striped hover>
              <tbody>
                {Object.keys(stateSchemaMap).map(fieldName => {
                  let value = state[fieldName]
                  let field = stateSchemaMap[fieldName]
                  return (
                    <tr key={fieldName}>
                      <th>{field.name}</th>
                      <td>{field.units === '$' ? field.units : ''}</td>
                      <td>
                        <input
                          type="number"
                          step={field.step}
                          value={field.display ? field.display(value) : value}
                          onClick={(e) => e.target.select()}
                          onChange={(e) => setState(prev => ({ ...prev, [fieldName]: _.get(field, 'setter', _.identity)(Number(e.target.value)) }))}
                        />
                      </td>
                      <td>{field.units === '$' ? '' : field.units}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Col>
          <Col>
            <h3>Debt to Income</h3>
            <div className="grid">
              {downPayments.map((downPayment, i) => (<Row key={i}>
                {interestRates.map((interestRate, j) => (
                  <div
                    key={`${i},${j}`}
                    className="grid-element"
                    onClick={(e) => {
                      setIsHover(!isHover)
                      setSelected([i, j])
                    }}
                    onMouseEnter={(e) => {
                      if (isHover) setSelected([i, j])
                    }}
                    onMouseLeave={(e) => {
                      if (isHover) setSelected([null, null])
                    }}
                    style={{ backgroundColor: colors(debtIncomeMatrix[i][j]) }}
                  >&nbsp;</div>
                ))}
              </Row>))}
            </div>
          </Col>
          <Col>
            <h3>Housing to Income</h3>
            <div className="grid">
              {downPayments.map((downPayment, i) => (<Row key={i}>
                {interestRates.map((interestRate, j) => (
                  <div
                    key={`${i},${j}`}
                    className="grid-element"
                    onClick={(e) => {
                      setIsHover(!isHover)
                      setSelected([i, j])
                    }}
                    onMouseEnter={(e) => {
                      if (isHover) setSelected([i, j])
                    }}
                    onMouseLeave={(e) => {
                      if (isHover) setSelected([null, null])
                    }}
                    style={{ backgroundColor: housingIncomeColors(housingIncomeMatrix[i][j]) }}
                  >&nbsp;</div>
                ))}
              </Row>))}
            </div>
          </Col>
        </Row>

        <Row>
          <Chart
            type='bar'
            options={{
              plugins: {
                title: {
                  display: true,
                  text: 'Amortization'
                }
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'Payment Number #'
                  }
                },
                y: {
                  type: 'linear',
                  display: true,
                  position: 'left',
                  title: {
                    display: true,
                    text: 'Principle + Interest'
                  },
                  ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value, index, ticks) {
                      return moneyString(value);
                    }
                  }
                },
                y1: {
                  type: 'linear',
                  display: true,
                  position: 'right',
                  ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value, index, ticks) {
                      return moneyString(value);
                    }
                  },
                  title: {
                    display: true,
                    text: 'Payment portion'
                  },
                }
              }
            }}
            data={{
              labels: Array(state.nPayments).fill().map((i, x) => x),
              datasets: _.isNumber(selected[0]) ? formatData(selected) : []
            }}
          />
        </Row>
      </div>
    </Container>
  )
}


export default IncomeHousing


import { Outlet, NavLink } from "react-router-dom";
import { ThemeProvider } from './themes.js';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useState } from "react";
import ChangeTheme from './themes'


export default function Layout({host}) {
    let [showThemes, setShowThems] = useState(false)
    return (
        <ThemeProvider style={{margin: "5vw"}}>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                <NavLink className="navbar-brand" to="/">Jonathan Edmonson</NavLink>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <NavLink className="nav-link" to="/photos">Photos</NavLink>
                        <NavLink className="nav-link" to="/contact">Contact</NavLink>
                        <NavDropdown title="Apps" id="basic-nav-dropdown">
                            <NavLink className="nav-link" to="/affordability">Loan Affordability</NavLink>
                            <NavLink className="nav-link" to="/memoryGame">Memory Game</NavLink>
                            <NavLink className="nav-link" to="/frequency-charts">Character Frequency</NavLink>
                            <NavLink className="nav-link" to="/sequences">Sequences</NavLink>
                            <NavLink className="nav-link" to="/client">Client Info</NavLink>
                            <NavLink className="nav-link" to="/binary">BinaryView</NavLink>
                            <NavLink className="nav-link" to="/schema">JSON Schema Viewer</NavLink>
                            <NavDropdown.Divider />
                            <NavLink className="nav-link" to="/credit">
                                Open Source
                            </NavLink>
                        </NavDropdown>
                        <a
                            href
                            className="nav-link"
                            onClick={(e) => setShowThems(~showThemes)}
                            style={{cursor: 'pointer'}}
                        >
                            Theme
                        </a>
                        {showThemes ? <ChangeTheme /> : null}
                    </Nav>
                </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className="applet">
                <Outlet />
            </div>
            <footer className="footer text-center">{(new Date()).getFullYear()} © Jonathan Edmonson</footer>
        </ThemeProvider>
    );
}
